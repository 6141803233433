import React, { memo, useEffect, useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import { StaticImage } from 'gatsby-plugin-image';
import { IoIosClose } from 'react-icons/io';
import FormNewsletter from './FormNewsletter';
import { loaded } from '../../utils/helpers';

const MenuItem = loadable(() => import('./MenuItem'));
MenuItem.preload();

export const query = graphql`
  query megaMenu {
    allPrismicMega(sort: { order: ASC, fields: data___top_level_menu_sort }) {
      nodes {
        data {
          highlighted_style
          top_level_menu_sort
          top_level_menu_item
          color
          top_level_menu_link {
            uid
            url
            type
            link_type
            document {
              ... on PrismicCategory {
                url
              }
              ... on PrismicBrand {
                url
              }
            }
          }
          mega_menu_images {
            mega_menu_image_label
            mega_menu_image {
              alt
              gatsbyImageData
            }
            mega_menu_image_link {
              url
              uid
              type
              link_type
              document {
                ... on PrismicCategory {
                  url
                }
                ... on PrismicBrand {
                  url
                }
              }
            }
          }
          body {
            ... on PrismicSliceType {
              slice_type
            }
            ...MegaDataBodyGroupOfLinks
            ...MegaDataBodyGroupOfLinks1
          }
        }
      }
    }
    prismicStoreConfiguration {
      data {
        footer_newsletter_label
        footer_newsletter_sub_label
      }
    }
  }
`;

const NavMenu = () => {
  const box = useRef(null);

  const data = useStaticQuery(query);

  const { footer_newsletter_label, footer_newsletter_sub_label } =
    data.prismicStoreConfiguration?.data || {};

  const navMenus = data.allPrismicMega.nodes.map((v) => v.data);

  useEffect(() => {
    function handleFixedHeaderOnMobile(main) {
      const header = document.getElementById('header');

      if (header) {
        if (window.innerWidth < 992) {
          main = header.offsetTop;
        }
        if (window.pageYOffset > main) {
          header.classList.add('fixed');
        } else {
          header.classList.remove('fixed');
        }
      }
    }

    function handleClickBtnToggleNav(close = false) {
      const header = document.getElementById('header');
      header.classList.toggle('-open');

      if (!close) {
        const btnToggleSubNavs = document.getElementsByClassName(
          'header__main__nav__link -has-child'
        );

        for (let i = 0; i < btnToggleSubNavs.length; i++) {
          btnToggleSubNavs[i].addEventListener('click', handleClickBtnToggleSubNavs);
        }
      }
    }

    function handleClickBtnToggleSubNavs(event) {
      event.preventDefault();
      /*main-nav*/
      const el = event.target;
      const elParent = el.parentElement;

      elParent.classList.toggle('-open');

      /*nav-content*/
      const navContent_nav_item_links = document.getElementsByClassName(
        'nav-content__nav__item__link'
      );
      for (let i = 0; i < navContent_nav_item_links.length; i++) {
        navContent_nav_item_links[i].addEventListener('click', handleClickNavContentNavItemLink);
      }
    }

    function handleClickNavContentNavItemLink(event) {
      const el = event.target;
      const elParent = el.parentElement;

      if (elParent.classList.contains('-has-child')) {
        event.preventDefault();
        elParent.classList.toggle('-open');
      }
    }

    /*menu fix when scroll*/
    if (loaded) {
      const main = document.getElementById('main').offsetTop;
      window.addEventListener('scroll', () => handleFixedHeaderOnMobile(main));
      document
        .getElementById('btn-toggle-nav')
        .addEventListener('click', () => handleClickBtnToggleNav(false));
      document
        .getElementById('header__main__btn-close')
        .addEventListener('click', () => handleClickBtnToggleNav(true));
    }

    return () => {
      if (loaded) {
        window.removeEventListener('scroll', () => handleFixedHeaderOnMobile(0));
      }
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        box.current &&
        !box.current.contains(event.target) &&
        document.getElementById('header').classList.contains('-open')
      ) {
        document.getElementById('header').classList.remove('-open');
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [box]);

  return (
    <div className="header__main">
      <div className="container-xl header__main__wrapper">
        <div ref={box}>
          <div className="header__main__logo d-lg-none d-block">
            <Link to="/" title="Activeskin" className="header__top__center__logo-mobile__link">
              <StaticImage
                width={140}
                src="../../images/activeskin-logo-black.webp"
                alt="Activeskin"
              />
            </Link>
          </div>
          <span
            id="header__main__btn-close"
            className="header__main__btn-close d-lg-none d-inline-block"
          >
            <IoIosClose />
          </span>
          <ul className="header__main__nav">
            {navMenus.map((menu, index) => (
              <MenuItem key={index} menu={menu} />
            ))}
            <li className="header__main__nav__item d-lg-none d-md-block">
              <Link className={`header__main__nav__link`} to="/salon">
                Activeskin Salon
              </Link>
            </li>
          </ul>
          <FormNewsletter
            footer_newsletter_label={footer_newsletter_label}
            footer_newsletter_sub_label={footer_newsletter_sub_label}
          />
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
